import React from "react";
import Container from "../Container";
import FooterSectionTitle from "./FooterSectionTitle";
import GooglePlayIcon from "assets/images/google-play-badge.svg";
import AppStoreIcon from "assets/images/app-store-badge.svg";
import CreativeCircle from "assets/images/creative-footer-circles.svg";
import { Link } from "react-router-dom";
import FooterMenu, { FooterMenuItem } from "./FooterMenu";
import { useTranslation } from "react-i18next";
import { mdiEmailOutline } from "@mdi/js";
import clsx from "clsx";
import useLogos from "hooks/useLogos";
import { BackendCode } from "models/BackendCode";

interface FooterProps {
  layoutHasSidebar?: boolean;
  userIsFinasticUser?: boolean;
  backendCode?: BackendCode;
}

const Footer: React.FC<FooterProps> = ({
  layoutHasSidebar,
  userIsFinasticUser,
  backendCode,
}) => {
  const { t } = useTranslation();
  const { Logo } = useLogos({
    backendCode,
    userIsFinasticUser,
  });

  const firstMenuItems: FooterMenuItem[] = [
    {
      icon: mdiEmailOutline,
      href: t("config.contactUrl"),
      label: t("footer.contactUs"),
      target: "_blank",
      id: "footerEmailAddress",
    },
  ];

  const secondMenuItems: FooterMenuItem[] = [
    {
      href: t("config.aboutUsUrl"),
      label: t("footer.aboutUs"),
      target: "_blank",
      id: "footerAboutUs",
    },
    {
      href: t("config.faqUrl"),
      label: t("footer.faq"),
      target: "_blank",
      id: "footerFaq",
    },
  ];

  return (
    <div
      className={clsx("bg-footer py-6 overflow-hidden relative", {
        "md:pl-56 pl-20": layoutHasSidebar,
      })}
    >
      <div className="bg-footer absolute h-full top-0 left-0 w-56" />
      <Container>
        <div className="relative">
          <img
            src={CreativeCircle}
            alt=""
            className="absolute h-96 top-1/3 transform -translate-y-1/2 -left-28 pointer-events-none select-none"
          />
          <div className="grid gap-8 grid-cols-12">
            <div className="lg:col-span-3 md:col-span-6 col-span-full">
              <Link to="/">
                <img src={Logo} alt="Axfina" className="h-6 mb-8" />
              </Link>
              <FooterMenu items={firstMenuItems} />
            </div>
            <div className="lg:col-span-4 md:col-span-6 col-span-full">
              <FooterSectionTitle>
                {t("footer.aboutFinastic")}
              </FooterSectionTitle>
              <div className="grid gap-4 grid-cols-12">
                <div className="xs:col-span-6 col-span-12">
                  <FooterMenu items={secondMenuItems} />
                </div>
              </div>
            </div>
            <div className="xl:col-span-3 lg:col-span-4 col-span-full">
              <div className="mb-4">
                <FooterSectionTitle>
                  {t("footer.downloadApp")}
                </FooterSectionTitle>
                <div className="flex -my-1 flex-wrap">
                  <a
                    href={process.env.REACT_APP_GOOGLE_PLAY_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={GooglePlayIcon}
                      alt="Google Play"
                      className="inline-block h-8 ml-2 my-1"
                    />
                  </a>
                  <a
                    href={process.env.REACT_APP_APP_STORE_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={AppStoreIcon}
                      alt="App Store"
                      className="inline-block h-8 ml-2 my-1"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
